<template>
  <div class="disclaimer">
    <div class="disclaimer-content">
      <h1 class="disclaimer-title">
        Thermaiscan | Disclaimer
      </h1>
      <p class="last-updated">
        Last updated Feb 25, 2022
      </p>
      <p>
        It is easy to capture and share clinical photographs and x-ray images using modern
        technologies.
        By using a smartphone and a small attachable thermal camera, the Thermaiscan tool is
        instantly
        accessible globally to health care providers and their customers. This improvement, however,
        has increased concerns regarding patient privacy and the safeguarding of protected health
        information. Health-care providers should understand the de identification process for
        patient
        photographs because this process fundamentally changes the expectations and requirements for
        how providers are to handle this information.
      </p>
      <h3 class="disclaimer-word">
        Disclaimer
      </h3>
      <p>
        The information provided by Thermaiscan (“we,” “us” or “our”) on https://thermaiscan.com/
        (the “Dashboard” https://app.thermaiscan.com) and our mobile application is for general
        information and diagnostics for finding the abnormalities in breast tissue that can be a
        sign
        of early or developed stages of breast cancer. All information on the Dashboard and our
        mobile
        application is provided in good faith, however at this point we make no representation or
        warranty of any kind, express or implied, regarding the accuracy, adequacy, validity,
        reliability, availability or completeness of any information on the Dashboard or our mobile
        application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY FOR ANY DATA LEAKAGE OF ANY
        KIND INCURRED AS A RESULT OF THE USE OF THE DASHBOARD OR OUR MOBILE APPLICATION OR RELIANCE
        ON
        ANY INFORMATION PROVIDED ON THE DASHBOARD AND OUR MOBILE APPLICATION. YOUR USE OF THE
        DASHBOARD AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE DASHBOARD
        AND
        OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
      </p>
      <h3>
        Managing clinical photographs and screenshots
      </h3>
      <p>
        This disclaimer addresses patient privacy concerns and forbids the doctor from the
        acquisition, transmission, and sharing of clinical photographs among health-care providers
        and
        beyond. The protocol of ThermaiScan provides guidelines for providers seeking to minimize
        the
        risk of noncompliance with privacy requirements as they adopt these new technologies into
        their practices. All photographic images were considered direct patient identifiers and
        therefore could not be “deidentified”.
      </p>
      <h3 class="testimonials-disclaimer">
        Testimonials Disclaimer
      </h3>
      <p>
        The Dashboard may contain testimonials by users of our products and/or services.
        These testimonials reflect real-life clinical information on the case of a patient.
        The testimonials on the Dashboard are submitted in various forms such as text, photo and/or
        forms, and are reviewed by admins. The testimonials on the Dashboard are not intended, nor
        should they be construed, as claims that our products are used to diagnose, treat, mitigate,
        cure, prevent or otherwise be used for any disease or medical condition. No testimonials
        are/can be clinically proven or evaluated.
      </p>
    </div>
    <button class="accept-button btn btn-sm"
            @click="acceptPrivacy">
      I accept
    </button>
  </div>
</template>

<script>
import {
  ACCEPT_PRIVACY,
} from '../../../../../store/modules/profile.module';

export default {
  name: 'disclaimerPage',
  methods: {
    acceptPrivacy() {
      this.$store.dispatch(ACCEPT_PRIVACY).then(() => {
        this.$router.push({ name: 'Dashboard' });
      });
    },
  },
};
</script>
<style lang="scss">
.disclaimer {
  margin: 36px 0 34px 30px;
  font-family: $openSans;

  p {
    font-size: 14px;
    text-align: justify;
  }

  h1 {
    font-weight: bold;
    font-size: 22px;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .last-updated {
    margin-bottom: 24px;
    font-weight: 600;
  }

  &-content {
    overflow: auto;
    height: calc(100vh - 139px);
    padding-right: 20%;
  }

  &-title, &-word, .testimonials-disclaimer, {
    text-transform: uppercase;
  }

  .accept-button {
    background-color: #81c8bd;
    border: none;
    text-transform: uppercase;
    color: white;
    margin-top: 24px;
    height: 45px;
    font-family: $openSans;
    padding-right: 50px;
    padding-left: 50px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 31px;

    &:focus, &:hover {
      box-shadow: none !important;
      // background-color: rgba(129, 200, 189, 0.7) !important;
      background-color: #74b4ab;
    }
  }
}
</style>
